/* eslint-disable react/no-danger */
import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/support/Header';
import styled from 'styled-components';

const Wrapper = styled.div`
  &&& {
    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

const HelpGuide: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO article title={t('translations:help-guide', 'Help Guide')} />
      <Container>
        <Header />
        <Wrapper dangerouslySetInnerHTML={{ __html: t('cms:help-guide') }} />
      </Container>
    </Layout>
  );
};

export default HelpGuide;
